import { Pipe, PipeTransform, inject } from '@angular/core';
import { TopWinner } from '@scpc/dto/top-winner';
import { ActivatedRoute } from '@angular/router';
import { FormatGameUrlPipe } from '@scpc/modules/games-lobby/pipes/format-game-url.pipe';
import { SlotGame } from '@scpc/dto/slot-game';
import { LiveGame } from '@scpc/dto/live-game';

@Pipe({ name: 'topWinnerLink' })
export class TopWinnerLinkPipe implements PipeTransform {

  private gameUrlPipe: FormatGameUrlPipe = inject(FormatGameUrlPipe);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  public transform(value: TopWinner): string {
    switch (value.product) {
      case 'SLOTS': {
        return this.gameUrlPipe.transform({ slot: value.game as SlotGame }, this.activatedRoute).routerLink;
      }
      case 'LIVE_GAMES': {
        return this.gameUrlPipe.transform({ game: value.game as LiveGame }, this.activatedRoute).routerLink;
      }
      case 'SPORTS': {
        return '/sports';
      }
      case 'LUCKY_NUMBERS': {
        return `/lucky-numbers/${value.draw.drawId}`;
      }
    }
    return '/';
  }
}
