import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventSport, EventTeam } from '@scpc/modules/sports/dto';
import { ImgFallbackDirective } from '@scpc/modules/common/directives/img-fallback.derictive';
import { CompetitorImagePipe } from '@scpc/modules/sports/pipes/competitor-image';
import { DefaultCompetitorImage } from '@scpc/modules/sports/pipes/default-competitor-image';
import { SportIdPipe } from '@scpc/modules/sports/pipes/sport-id';

@Component({
  selector: 'scp-sports-competitor-icon',
  templateUrl: './competitor-icon.component.html',
  styleUrls: ['./competitor-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ImgFallbackDirective, CompetitorImagePipe, DefaultCompetitorImage, SportIdPipe],
})
export class CompetitorIconComponent {

  @Input()
  public sport: EventSport;

  @Input()
  public team: EventTeam;

  @Input()
  public theme: 'light' | 'dark' = 'light';

  @Input()
  public size: number = 44;


}
