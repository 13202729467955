@switch (product) {
  @case ('LUCKY_NUMBERS') {
    <scp-draw-icon [drawId]='draw.drawId' [size]='22' theme='dark'></scp-draw-icon>
  }
  @case ('SLOTS') {
    @if (game.icon?.url) {
      <img class='scp-top-winners-icon__game'
           [src]='game.icon.url'
           height='28px' width='42' alt='' loading='lazy'>
    } @else {
      <img class='scp-top-winners-icon__game'
           src='/assets/common/slots/{{game.provider.type.toLowerCase()}}/{{game.gameId}}.jpg'
           height='28px' width='42' alt='' loading='lazy'>
    }
  }
  @case ('LIVE_GAMES') {
    @if (game.icon?.url) {
      <img class='scp-top-winners-icon__game'
           [src]='game.icon.url'
           height='28px' width='42' alt='' loading='lazy'>
    } @else {
      <img class='scp-top-winners-icon__game'
           src='/assets/common/live-games/{{game.provider.type.toLowerCase()}}/{{game.gameId}}.jpg'
           height='28px' width='42' alt='' loading='lazy'>
    }
  }
  @case ("SPORTS") {
    @if (event.season) {
      <scp-sports-competitor-icon
        theme='dark'
        [size]='20'
        [team]='event.season'
        [sport]='event.sport'>
      </scp-sports-competitor-icon>
    } @else {
      <div class='scp-top-winners-icon__sport-teams'>
        <scp-sports-competitor-icon theme='dark' [size]='20'
                                    [team]='event.homeTeam'
                                    [sport]='event.sport'>
        </scp-sports-competitor-icon>
        <scp-sports-competitor-icon theme='dark' [size]='20'
                                    [team]='event.awayTeam'
                                    [sport]='event.sport'>
        </scp-sports-competitor-icon>
      </div>
    }
  }
}
