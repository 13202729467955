<div class='scp-top-winners'>
  @if (loading) {
    <div class='scp-top-winners__mobile'>
      <div class='scp-top-winners__mobile__title'>{{ 'Winning now' | translate }}</div>
      <div class='scp-top-winners__mobile__products ssc-square'></div>
      <table class='scp-top-winners__mobile__table-preloader'>
        <thead>
        <th>
          <div class='scp-top-winners__mobile__title ssc-square'></div>
        </th>
        </thead>
        <tbody>
          @for (item of ' '.repeat(5).split(''); track $index) {
            <tr>
              <td>
                <div class='scp-top-winners__mobile__table-row-preloader ssc-square'></div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  } @else {
    <div class='scp-top-winners__mobile'>
      <div class='scp-top-winners__mobile__title'>{{ 'Winning now' | translate }}</div>
      <scp-segmented [segments]='segments'
                     [active]='segment'
                     [sameWidth]='false'
                     [small]='true'
                     [height]='32'
                     [background]='"#DCE7EB"'
                     (change)='changeProduct($event)'>
      </scp-segmented>
      <div class='scp-top-winners__mobile__table'>
        <div class='scp-top-winners__mobile__table__title scp-top-winners__mobile__table__game-title'>
          {{ 'Game/Player' | translate }}
        </div>
        <div class='scp-top-winners__mobile__table__title'>{{ 'Time' | translate }}</div>
        <div class='scp-top-winners__mobile__table__title'>{{ 'Win' | translate }}</div>
        @for (item of mobileItems; track trackBy(item, $index); let odd = $even) {
          @if (item) {
            <a class='scp-top-winners__mobile__table__item' [class.odd]='odd' [routerLink]='item | topWinnerLink'>
              <span>
                 <scp-top-winners-icon [product]='item.product'
                                       [game]='item.game'
                                       [draw]='item.draw'
                                       [event]='item.event'>
                 </scp-top-winners-icon>
              </span>
              <span class='scp-top-winners__mobile__table__game'>
                <span class='scp-top-winners__mobile__table__event'>
                  {{ item.title }}
                </span>
                <span class='scp-top-winners__mobile__table__winner'>
                  {{ item.customer }}
                </span>
              </span>
              <span> {{ item.wonAt | date: 'HH:mm' }}</span>
              <span class='scp-top-winners__mobile__table__win'>{{ item.win | formatMoney: true: false }}</span>
            </a>
          } @else {
            <div class='scp-top-winners__mobile__table__item empty' [class.odd]='odd'></div>
          }
        }
      </div>
    </div>
  }
  <div class='scp-top-winners__desktop'>
    @if (desktop) {
      <div class='scp-top-winners__desktop__content'>
        <div class='swiper swiper-top-winners'>
          @if (loading) {
            <div class='swiper-wrapper'>
              @for (item of ' '.repeat(16).split(''); track $index) {
                <div class='swiper-slide'>
                  <div class='ssc-square'></div>
                </div>
              }
            </div>
          } @else {
            <div class='swiper-wrapper'>
              @for (item of topWinners.winningsNow; track trackBy(item, $index)) {
                <div class='swiper-slide'>
                  <a class='scp-top-winners__desktop__content__item' [routerLink]='item | topWinnerLink'>
                    <scp-top-winners-icon [product]='item.product'
                                          [game]='item.game'
                                          [draw]='item.draw'
                                          [event]='item.event'>
                    </scp-top-winners-icon>
                    <div>
                      <div class='scp-top-winners__desktop__content__item__event'>
                        {{ item.title }}
                      </div>
                      <div class='scp-top-winners__desktop__content__item__row'>
                        <div class='scp-top-winners__desktop__content__item__win'>
                          {{ item.win | formatMoney: true: false }}
                        </div>
                        <div class='scp-top-winners__desktop__content__item__winner'>
                          {{ item.customer }}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
    <button class='scp-top-winners__desktop__content__action' (click)='toggle()' matRipple>
      <img src='/assets/images/svg/top-winners.svg' height='24' width='24' alt='' loading='lazy'>
      <span>{{ 'Winning now' | translate }}</span>
      <div>
        @if (desktop) {
          <img src='/assets/images/svg/x-2.svg' height='32' width='32' alt=''>
        } @else {
          <img src='/assets/images/svg/top-winners-open.svg' height='24' width='24' alt=''>
        }
      </div>
    </button>
  </div>
</div>
