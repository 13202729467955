import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ListItemComponent } from '@scpc/modules/common/components/list-item/list-item.component';
import { SimpleBonusProgramOffer } from '@scpc/dto/bonus-program-offer';
import { Swiper } from 'swiper';
import { FreeMode } from 'swiper/modules';
import { catchError } from '@scpc/utils/dom.utils';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { formatLeftTime } from '@scpc/modules/promotions/utils/date';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractSwiperComponent } from '@scpc/modules/lobby/components/abstract.swiper.component';

@Component({
  selector: 'scp-lobby-item-bonuses',
  templateUrl: './lobby-item-bonuses.component.html',
  styleUrls: ['./lobby-item-bonuses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ListItemComponent],
})
export class LobbyItemBonusesComponent extends AbstractSwiperComponent implements AfterContentInit, OnDestroy {

  @Input()
  public offers: SimpleBonusProgramOffer[];

  @Input()
  public breakpoint: number;

  constructor(private readonly translateService: TranslateService,
              private readonly sanitizer: DomSanitizer,
              private readonly elementRef: ElementRef<HTMLDivElement>) {
    super();
  }

  public ngAfterContentInit() {
    this.idle.requestIdleCallback(() => {
      this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('.swiper') as HTMLElement, {
        init: this.isBrowser,
        modules: [FreeMode],
        freeMode: true,
        touchRatio: 1.5,
        speed: 400,
        resistance: true,
        resistanceRatio: 0.5,
        slidesOffsetBefore: 15,
        slidesOffsetAfter: 15,
        slidesPerView: 'auto',
        resizeObserver: true,
        updateOnWindowResize: true,
        on: { ...this.on },
      });
    });
    this.updateTimer();
    if (this.isBrowser) {
      this.zone.runOutsideAngular(() => interval(1_000).pipe(
        takeWhile(() => Math.max(...this.offers.map(o => o.expiryDate || o.bonusProgram.endedAt)) >= new Date().getTime()),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => {
        this.updateTimer();
        this.zone.run(() => this.changeDetectorRef.markForCheck());
      }));
    }
  }

  public ngOnDestroy(): void {
    catchError(() => this.swiper.destroy());
  }

  private updateTimer(): void {
    const now: number = Date.now();
    for (const offer of this.offers) {
      const time = formatLeftTime(offer.expiryDate || offer.bonusProgram.endedAt);
      const diff = (offer.expiryDate || offer.bonusProgram.endedAt) - now;
      const color = '--scp-theme-color-' + (diff < (this.breakpoint || 24) * 3600000 ? '14' : '03');
      offer.description = this.sanitizer.bypassSecurityTrustHtml(time ? this.translateService.instant('BO.TIMER.' + offer.status, {
        time: `<span style='color: var(${color})'>${time}</span>`,
      }) : '');
    }
  }

}
