<div class='scp-games-swiper'>
  <div class='swiper' #swiperContainer>
    <div class='swiper-wrapper'>
      @for (offer of offers; track offer.id) {
        <div class='swiper-slide'>
          <scp-list-item [highlighted]='offer.status === "ACCEPTED"'
                         imgSrc='/assets/images/svg/bonus-offer.svg'
                         [url]="'/account/bonus-programs-offers/' + offer.id"
                         title='{{ offer.bonusProgram.name }}'
                         [description]='offer.description'>
          </scp-list-item>
        </div>
      }
    </div>
  </div>
</div>
