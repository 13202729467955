import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Segment, SegmentedComponent } from '@scpc/modules/common/components/segmented/segmented.component';
import { ConfigService, Product } from '@scpc/modules/common/services/config.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MatRipple } from '@angular/material/core';
import { CookiesService } from '@scpc/modules/cookies/cookies.service';
import { Swiper } from 'swiper';
import { Autoplay, Manipulation } from 'swiper/modules';
import { catchError } from '@scpc/utils/dom.utils';
import { TopWinnersIconComponent } from '@scpc/modules/common/components/top-winners-icon/top-winners-icon.component';
import { FormatMoneyPipe } from '@scpc/modules/common/pipes/format-money.pipe';
import { TopWinnerLinkPipe } from '@scpc/modules/common/pipes/top-winner-link.pipe';
import { TopWinner, TopWinners } from '@scpc/dto/top-winner';
import { FormatGameUrlPipe } from '@scpc/modules/games-lobby/pipes/format-game-url.pipe';
import { CmsService } from '@scpc/modules/common/services/cms.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin, of } from 'rxjs';
import { ScpWebSocketService } from '@scpc/modules/common/services/scp.websocket.service';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';

@Component({
  selector: 'scp-top-winners',
  templateUrl: './top-winners.component.html',
  styleUrls: ['./top-winners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DatePipe,
    RouterLink,
    TranslateModule,
    SegmentedComponent,
    MatRipple,
    TopWinnersIconComponent,
    FormatMoneyPipe,
    TopWinnerLinkPipe,
  ],
  providers: [FormatGameUrlPipe],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    ngSkipHydration: '',
  },
})
export class TopWinnersComponent implements OnInit, OnDestroy {

  @Input()
  public product: string;

  protected loading: boolean = true;
  protected segments: Segment<string>[];
  protected segment: Segment<string>;
  protected desktop: boolean;
  protected mobileItems: TopWinner[] = [];
  protected topWinners: TopWinners;

  private cookiesService: CookiesService = inject(CookiesService);
  private configService: ConfigService = inject(ConfigService);
  private cmsService: CmsService = inject(CmsService);
  private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private elementRef: ElementRef = inject(ElementRef);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private idle: Idle = inject(Idle);
  private scpWebSocketService: ScpWebSocketService = inject(ScpWebSocketService);
  private zone: NgZone = inject(NgZone);
  private isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));
  private swiper: Swiper;
  private products: Map<string, Product>;

  public ngOnInit(): void {
    this.desktop = this.cookiesService.get('winners') !== 'f';
    if (this.isBrowser) {
      this.idle.requestIdleCallback((): void => {
        forkJoin([
          this.cmsService.getTopWinners(),
          of(new Map<string, Product>(this.configService.products.map((p: Product) => [p.type, p]))),
        ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: [TopWinners, Map<string, Product>]): void => {
          this.topWinners = data[0];
          this.products = data[1];
          this.update();
          this.scpWebSocketService.subscribe('top-winners');
          this.scpWebSocketService.on('top-winners-update')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data: string): void => {
              this.topWinners = JSON.parse(data);
              this.zone.run(() => this.update());
            });
        });
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.isBrowser) {
      this.scpWebSocketService.unsubscribe(`top-winners`);
    }
  }

  protected changeProduct(segment: Segment<string>): void {
    this.zone.run((): void => {
      this.segment = segment;
      this.product = segment.value;
      this.updateItems();
    });
  }

  protected trackBy(item: TopWinner, $index: number): string {
    if (!item) {
      return $index + '';
    }
    return item.title + item.customer + item?.wonAt + item.win + item.draw?.drawId + item.game?.gameId + item.event?.homeTeam?.id + item.event?.season?.id;
  }

  protected toggle(): void {
    this.desktop = !this.desktop;
    if (!this.desktop) {
      this.cookiesService.put('winners', 'f');
    } else {
      this.cookiesService.remove('winners');
    }
    this.updateSlider();
  }

  private update(): void {
    this.segments = this.topWinners.products
      .map((p: { type: string }): Segment<string> | null => {
        const product: Product = this.products.get(p.type);

        if (!product) {
          return null;
        }

        return {
          label: product.name,
          value: product.type,
        };
      })
      .filter((segment: Segment<string>) => !!segment);
    this.segment = this.segments.find((s: Segment<string>): boolean => s.value === this.product) || this.segments[0];
    this.loading = false;
    this.updateItems();
    this.changeDetectorRef.detectChanges();
    this.updateSlider();
  }

  private updateItems(): void {
    this.mobileItems = this.topWinners.winningsNow.filter((item: TopWinner): boolean => item.product === this.segment.value);
    if (this.mobileItems.length < 5) {
      this.mobileItems = this.mobileItems.concat(Array(5 - this.mobileItems.length));
    }
  }

  private updateSlider(): void {
    if (this.desktop) {
      if (!this.swiper) {
        this.changeDetectorRef.detectChanges();
        this.zone.runOutsideAngular((): void => {
          this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('.swiper-top-winners') as HTMLElement, {
            init: this.isBrowser,
            modules: [Autoplay, Manipulation],
            loop: true,
            autoplay: this.isBrowser
              ? { delay: 5000, disableOnInteraction: false }
              : /* istanbul ignore next */ false,
            slidesPerView: 'auto',
            centeredSlides: false,
            centeredSlidesBounds: true,
            updateOnWindowResize: true,
          });
        });
      }
    } else {
      catchError(() => this.swiper?.destroy());
      this.swiper = null;
    }
  }

}
