import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { DrawIconComponent } from '@scpc/modules/lucky-numbers/components/draw-icon/draw-icon.component';
import { CompetitorIconComponent } from '@scpc/modules/sports/components/competitor-icon/competitor-icon.component';
import { Game } from '@scpc/modules/games-lobby/model';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { Event } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-top-winners-icon',
  templateUrl: './top-winners-icon.component.html',
  styleUrls: ['./top-winners-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DrawIconComponent,
    CompetitorIconComponent,
  ],
})
export class TopWinnersIconComponent {

  @Input()
  public product: string;

  @Input()
  public game: Partial<Game>;

  @Input()
  public draw: Partial<Draw>;

  @Input()
  public event: Partial<Event>;


}
